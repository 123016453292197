import { Link } from "gatsby";
import React from "react";
import { useAuth0 } from "../../../context/react-auth0-spa";

const Header = ({
  location,
  color,
  title = "",
  titleSize = "",
  isModel = false,
}) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  let areUserInfo = null;
  if (user) {
    areUserInfo = Object.keys(user).length < 5;
  }

  const linkStyle = `block mx-auto  py-2 px-0 font-semibold hover:no-underline hover:text-black xl:text-base text-gray-800 whitespace-no-wrap ${
    color === "bg-white" ? "border-white" : ""
  } ${areUserInfo ? `pointer-events-none opacity-50` : ``}`;

  return (
    <div className={color}>
      <div id="skipnav">
        <a href="#main">Skip to main content</a>
      </div>
      <nav className="mx-auto" style={{ padding: '10px', boxSizing: 'border-box' }}>
        <div className="flex items-center justify-center px-3 py-2 lg:px-12 lg:py-2 lg:p-0">
          <div className="flex items-center space-x-3">
            <Link
              to={isAuthenticated ? "/user" : "/"}
              style={{
                textAlign: 'center',
                padding:'8px 10px',
                fontSize: '12px'
              }}
              className={`${linkStyle}`}
              activeClassName="text-black border-b border-gray-900"
            >
              {isAuthenticated ? "My NEWA dashboard" : "NEWA home"}
            </Link>

            {!isAuthenticated && (
              <>
                <Link
                  to="/crop-and-pest-management"
                  style={{
                    textAlign: 'center',
                    padding:'8px 10px',
                    fontSize: '12px'
                  }}
                  className={`${linkStyle}`}
                  activeClassName="text-black border-b border-gray-900"
                >
                  IPM crop forecasts
                </Link>
                <Link
                  to="/weather-tools"
                  style={{
                    textAlign: 'center',
                    padding:'8px 10px',
                    fontSize: '12px'
                  }}
                  className={`${linkStyle}`}
                  activeClassName="text-black border-b border-gray-900"
                >
                  IPM weather tools
                </Link>
                <Link
                  to="/help"
                  style={{
                    textAlign: 'center',
                    padding:'8px 10px',
                    fontSize: '12px'
                  }}
                  className={`${linkStyle}`}
                  activeClassName="text-black border-b border-gray-900"
                >
                  NEWA help
                </Link>
                <button
                  className={`${linkStyle}`}
                  onClick={() => {
                    loginWithRedirect({
                      appState: {
                        returnTo: window.location.pathname,
                      }
                    })
                  }}
                  style={{
                    backgroundColor: "#508227", // Green rectangle background
                    borderRadius: "5px", // Rounded corners
                    color: "white", // White text
                    textAlign: 'center',
                    padding:'8px 10px',
                    fontSize: '12px',
                    border: "none", // Border
                    transition: "background-color 0.3s ease", // Transition for smooth hover effect
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#45a049";
                  }} // Darker green on hover
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "#508227";
                  }} // Revert to original color when not hovered
                >
                  Sign In
                </button>
              </>
            )}

            {isAuthenticated && (
              <>
                <Link
                  to="/user/profile"
                  state={{ tab: areUserInfo ? 0 : 2 }}
                  style={{
                    textAlign: 'center',
                    padding:'8px 10px',
                    fontSize: '12px'  // Smaller font sizes
                  }}
                  className={`${linkStyle}`}
                  activeClassName="text-black border-b border-gray-900"
                >
                  User settings
                </Link>
                <Link
                  to="/help"
                  style={{
                    textAlign: 'center',
                    padding:'8px 10px',
                    fontSize: '12px'
                  }}
                  className={`${linkStyle}`}
                  activeClassName="text-black border-b border-gray-900"
                >
                  NEWA help
                </Link>
              </>
            )}

            {isAuthenticated && (
              <button
                className={`${linkStyle}`}
                onClick={logout}
                style={{
                  backgroundColor: "#508227", // Green rectangle background
                  borderRadius: "5px", // Rounded corners
                  color: "white", // White text
                  textAlign: 'center',
                  padding:'8px 10px',
                  fontSize: '12px',
                  border: "none", // Border
                  transition: "background-color 0.3s ease", // Transition for smooth hover effect
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#45a049";
                }} // Darker green on hover
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "#508227";
                }} // Revert to original color when not hovered
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
